<template>
  <div class="flex flex-col">
    <div class="p-4 text-white font-bold text-base relative" :style="localbgColor">
      PAQUETE
      <span
        class="
          absolute
          right-4
          -top-4
          w-20
          h-20
          rounded-full
          flex
          justify-center
          items-center
          border-8 border-white
        "
        ><span
          class="
            border-10
            w-16
            h-16
            bg-white
            rounded-full
            flex
            justify-center
            items-center
            text-3xl
            font-extrabold
          "
          :style="[borderColor, textColor]"
          >{{ idGrupo }}</span
        ></span
      >
    </div>

    <h3 class="self-center text-base mt-2 font-semibold text-gray-500">{{ nombreGrupo }}</h3>

    <div
      class="p-5 mt-4 border border-solid rounded-xl align-content-center text-white text-base"
      :class="colorFlecha"
      :style="localbgColor"
    >
      <div class="flex flex-col gap-6">
        <div v-for="indicador in indicadores" :key="indicador.idindicador">
          <router-link
            :to="goTo + indicador.idindicador"
            class="flex items-center gap-4 hover:text-gray-300"
          >
            <img
              :src="require(`@/assets/${idGrupo == 1 ? 'gestante' : 'bebe'}.png`)"
              class="w-16 h-16 rounded-full border-4 border-gray-400 p-1 bg-white"
              alt="Logo minsa"
            />
            <p>{{ indicador.abreviatura }}.- {{ indicador.tituloreporte }} {{ indicador.adicional }} </p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    idGrupo: {
      type: String,
      default: ""
    },
    indicadores: {
      type: Array,
      default() {
        return [];
      }
    },
    bgColor: {
      type: String,
      default: "#1DA57A"
    },
    nombreGrupo: {
      type: String,
      default: ""
    },
    colorFlecha: {
      type: String,
      default: "morado-arrow-top"
    },
    goTo: {
      type: String,
      default: "/"
    }
  },
  setup(props) {
    const localbgColor = computed(() => {
      return "background-color: " + props.bgColor;
    });

    const borderColor = computed(() => {
      return "border-color: " + props.bgColor;
    });
    const textColor = computed(() => {
      return "color: " + props.bgColor;
    });

    return {
      localbgColor,
      borderColor,
      textColor
    };
  }
};
</script>

<style scoped>
/* ------ MORADO -------- */
.morado-arrow-top {
  position: relative;
}

.morado-arrow-top:before,
.morado-arrow-top:after {
  content: "";
  position: absolute;
  right: 40px;
  top: -30px;
  border-top: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #a863a5;
  border-left: 15px solid transparent;
}

.morado-arrow-top:after {
  border-bottom: 15px solid #a863a5;
  top: -29px;
}
/* ------ VERDE -------- */
.verde-arrow-top {
  position: relative;
}

.verde-arrow-top:before,
.verde-arrow-top:after {
  content: "";
  position: absolute;
  right: 40px;
  top: -30px;
  border-top: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #abca29;
  border-left: 15px solid transparent;
}

.verde-arrow-top:after {
  border-bottom: 15px solid #abca29;
  top: -29px;
}

/* ------ NARANJA -------- */
.naranja-arrow-top {
  position: relative;
}

.naranja-arrow-top:before,
.naranja-arrow-top:after {
  content: "";
  position: absolute;
  right: 40px;
  top: -30px;
  border-top: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #f39200;
  border-left: 15px solid transparent;
}

.naranja-arrow-top:after {
  border-bottom: 15px solid #f39200;
  top: -29px;
}

/* ------ AZUL -------- */
.azul-arrow-top {
  position: relative;
}

.azul-arrow-top:before,
.azul-arrow-top:after {
  content: "";
  position: absolute;
  right: 40px;
  top: -30px;
  border-top: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #1c84c2;
  border-left: 15px solid transparent;
}

.azul-arrow-top:after {
  border-bottom: 15px solid #1c84c2;
  top: -29px;
}
</style>
